import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/NewsView.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('../views/ServiceView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/service-doc1',
    name: 'serviceDoc1',
    component: () => import('../views/ServiceDoc1View.vue')
  },
  {
    path: '/service-doc2',
    name: 'serviceDoc2',
    component: () => import('../views/ServiceDoc2View.vue')
  },
  {
    path: '/service-doc3',
    name: 'serviceDoc3',
    component: () => import('../views/ServiceDoc3View.vue')
  }, {
    path: '/news/:id',
    name: 'newsDetail',
    component:  () => import('../views/NewsDetailView.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
