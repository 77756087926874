<template>
  <div class="home">
    <Banner/>

    <div class="index-min">
      <!-- 公司简介 -->
      <div class="index-mingsjj">
        <div class="index-mingsjjtit">
          公司简介
          <span><a href=""><img src="../assets/img/index_11.png" alt=""></a></span>
        </div>
        <div class="index-mingsjjmin">
          <div class="index-mmpic">
            <img src="../assets/img/indexpic_07.jpg" alt="">
          </div>
          <div class="index-mmptxt">
            <strong>浙江亲和货运代理有限公司</strong>是一家经工商局注册，国家有关部门审批的专业国际货运公司，拥有足够的经营资格，是获得政府批准的合法经营国际货运的服务中心。
            公司现拥有一支经验丰富，精通业务的专业人才队伍，他们受过专业的培训
          </div>
        </div>
      </div>
      <!-- 运单查询 -->
      <div class="index-minhxyw">
        <div class="index-mingsjjtit">
          运单查询
          <!-- <span><a href=""><img src="../assets/img/index_11.png" alt=""/></a></span> -->
        </div>
        <div class="index-minhxywmin">
          <div class="index-mmpic">
            <form method="get" target="_blank" action="http://116.62.170.179:8082/qhsys/nosecurity/tracks2"
                  style="padding: 10px;background: #F4F5F6; border-radius: 6px;">
              <textarea name="ydhs" placeholder="输入订单号查询，查询多个以逗号、空格或回车键隔开"
                        style="width: 310px;height: 44px;font-size: 16px;" maxlength="50"></textarea>
              <button class="btn" style="width:80px;height: 51px;float: right;">
                <span class="icon-search">&nbsp;</span>查询
              </button>
            </form>
          </div>
          <div class="index-mmpic" style="padding:15px 60px; background: #F4F5F6; border-radius: 6px;">
            <div class="img-login" style="float: left;"></div>
            <div id="personal" style="width:220px;margin-left: 100px;">
              <div>
                <p>亲和会员</p>
                <a href="http://116.62.170.179:8082/qhsys" class="buttom" target="_blank">马上登录<span></span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 新闻中心 -->
      <div class="index-minxwzx">
        <div class="index-mingsjjtit">
          新闻中心
          <span><a href="/news"><img src="../assets/img/index_11.png" alt=""></a></span>
        </div>
        <div class="index-minxwzxmin">
          <ul>
            <li v-for="item in notices" :key="item.noticeId">
              <i></i>
              <a :href="'/news/' + item.noticeId + '?type=1'">{{ item.noticeTitle }}</a>
              <!-- <span style="float: right;">[item.createTime]</span> -->
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from '@/components/Banner.vue'
import {getLastNotices} from "@/api/webApi";

export default {
  name: 'HomeView',
  components: {
    Banner
  },
  data() {
    return {
      notices: [],
    }
  },
  created() {
    // 获取最近的通知
    getLastNotices().then(res => {
      this.notices = res.data
    })
  }
}
</script>
