import request from '@/utils/request'

// 获取最近的通知（官网首页-新闻中心显示用）
export function getLastNotices() {
    return request({
        url: '/openApi/v1/getLastNotices',
        method: 'get'
    })
}

// 查询通知公告列表
export function listNotices() {
    return request({
        url: '/openApi/v1/listNotices',
        method: 'get'
    })
}

// 获取通知公告详情
export function getNoticeDetail(id) {
    return request({
        url: '/openApi/v1/getNoticeDetail?id='+id,
        method: 'get'
    })
}

// 查询新闻内容列表
export function listNews() {
    return request({
        url: '/openApi/v1/listNews',
        method: 'get'
    })
}

// 获取新闻内容详情
export function getNewsDetail(id) {
    return request({
        url: '/openApi/v1/getNewsDetail?id='+id,
        method: 'get'
    })
}
