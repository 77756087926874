<template>
  <div class="banner">
    <div id="indexbanner" class="indexbanner">
      <div class="hd">
        <ul>
          <li class=""></li>
          <li class=""></li>
          <li class="on"></li>
        </ul>
      </div>
      <div class="bd">
        <ul>
          <li style="display: none;"><img src="../assets/img/scheme-bj.jpg" alt=""></li>
          <li style="display: none;"><img src="../assets/img/se-bj.jpg" alt=""></li>
          <li style="display: list-item;"><img src="../assets/img/banner_10.jpg" alt=""></li>
        </ul>
      </div>
    </div>
<!--    <script type="text/javascript">-->
<!--      jQuery(".indexbanner").slide({mainCell: ".bd ul", autoPlay: true});-->
<!--    </script>-->
  </div>
</template>

<script>
export default {
  name: 'Banner'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
