<template>
  <div id="header">
    <div class="header">
      <div class="hesder-top">
        <span><img src="../assets/img/index_03.png" alt=""></span>服务热线：400-899-8877
      </div>
      <div class="header-nav">
        <ul>
          <li>
            <router-link to="/"><span>首　 页</span></router-link>
          </li>
          <li>
            <router-link to="/news"><span>新闻中心</span></router-link>
          </li>
          <li>
            <router-link to="/service"><span>服务专区</span></router-link>
          </li>
          <li>
            <router-link to="/about"><span>关于亲和</span></router-link>
          </li>
          <li>
            <router-link to="/contact"><span>联系我们</span></router-link>
          </li>
        </ul>
      </div>
      <div class="Logo">
        <div class="Logo-img" style="padding-top: 0;">
          <img src="../assets/img/logo.png" alt="" style="height:50px;">
        </div>
        <div class="Logo-min">
          <div class="Logo-mintit">浙江亲和货运代理有限公司</div>
          <div><a href="">ZHEJIANG QINHE FREIGHT FORWARDERS CO.,LTD</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*导航激活状态样式*/
.header-nav ul li a.router-link-exact-active {
  color: #197abf;
}
</style>
