<template>
  <div class="index-bottom">
    <div class="index-bottommin">
      <div class="index-bottommyq">
        友情链接：
        <a target="_blank" href="http://www.fedex.com/cn/">FedEx</a>
        <a target="_blank" href="http://www.ups.com/">UPS</a>
        <a target="_blank" href="https://www.tnt.com/express/zh_cn/site/home.html">TNT</a>
        <a target="_blank" href="http://www.cn.dhl.com/zh.html">DHL</a>
      </div>
      <div class="index-bottomcopy">
        <div class="index-bottomcopyL">
          <img src="../assets/img/index_39.jpg" alt="" style="height:60px;"
               ondblclick="window.open('/backstage');">
        </div>
        <div class="index-bottomcopyL2" style="left:220px;">
          <p>
            版权所有:中国.浙江亲和货运代理有限公司
            <a href="https://beian.miit.gov.cn" target="_blank">浙ICP备14023296号</a>
          </p>
          <p>
            <!--
            <a href="http://idinfo.zjaic.gov.cn/bscx.do?method=hddoc&amp;id=3301052005001314"
               style="display:inline-block;text-decoration:none;height:20px;line-height:20px;" target="_blank">
              <img src="http://idinfo.zjaic.gov.cn/images/i_lo2.gif" style="float:left; width:25px; height:25px">
            </a>
            -->
            <a href="http://idinfo.zjaic.gov.cn/bscx.do?method=hddoc&amp;id=3301052005001314"
               style="display:inline-block;text-decoration:none;height:20px;line-height:20px;" target="_blank">
              工商备案 3301052005001314号
            </a>
            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010302001386"
               style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"
               target="_blank">
              <img src="http://www.beian.gov.cn/img/ghs.png" style="float:left;width:25px; height:25px">
            </a>
            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010302001386"
               style="display:inline-block;text-decoration:none;height:20px;line-height:20px;" target="_blank">
              浙公网安备 33010302001386号
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
